<template>
  <div class="centerclass" v-wechat-title="$route.meta.title">
    <navigation :msg="msg"></navigation>
    <div class="desclass">
      <div class="divone"></div>
      <div>
        <p>背调说明</p>
      </div>
      <div class="divtwo"></div>
    </div>

    <p class="pcone">24小时内未完成授权，本次背调过程作废</p>
    <p class="pctwo">委托公司：上海振翊软件科技有限公司</p>
    <div class="process">
      <p>操作流程</p>
      <div class="proliu">
        <div class="liuclass">
          <div>
            <div>1</div>
            <p>确认授权</p>
          </div>
          <div>
            <div>2</div>
            <p>信息完善和实名认证</p>
          </div>
        </div>
      </div>
    </div>
    <button @click="toseke" class="bton bbnn">
      下一步
    </button>
    <!-- <div class="xieyi">
      <div>
        <van-radio-group v-model="radio">
          <van-radio name="1" checked-color="#FBA110"
            >我已阅读并同意《萝卜猎手隐私协议》</van-radio
          >
        </van-radio-group>
      </div>
      
    </div> -->
    <div class="box">
      <van-checkbox checked-color="#FBA110" v-model="checked"></van-checkbox>
      <div class="box1">我已阅读并同意《萝卜猎手隐私协议》</div>
      <p @click="refuse">拒绝授权</p>
    </div>
    <div v-if="ismsk" class="msk"></div>
    <div v-if="ismsk" class="popups">
      <div>
        <div></div>
        <div><img @click="ismsk = false" src="../../assets/delimg.png" /></div>
      </div>
      <p>您是否确认拒绝授权？</p>
      <div>
        <button @click="cliok" id="btqone">{{ okmsg }}</button>
        <button @click="clino" id="btqtwo">{{ nomsg }}</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      msg: "授权中心",
      protocol: "",
      radio: "",
      ismsk: false, //是否显示遮罩层
      okmsg: "确认",
      nomsg: "取消",
      checked: "",
      mymsg:{}  //信息
    };
  },
  mounted(){

  },
  methods: {
    toseke(){
    if(this.checked==true){
          this.$router.push('/backinformation')
      }else{
        this.$toast.fail("请勾选协议！");
      }
      // this.$router.push({
      //   name:"backinformation",
      //   params:{
      //     name:this.$route.query.name,
      //     phone:this.$route.query.phone,
      //     idNo:this.$route.query.idNo
      //   }
      // })
    },
    refuse() {
      this.ismsk = true;
    },
    cliok() {
      if (this.okmsg == "残忍拒绝") {
        this.$router.push("/backrefuse");
      }
      this.okmsg = "残忍拒绝";
      this.nomsg = "同意授权";
    },
    clino() {
      this.ismsk = false;
    },
  },
};
</script>
<style scoped>
.desclass {
  display: flex;
  align-items: center;
  margin-left: 10%;
  margin-top: 0.43rem;
  margin-bottom: 0.29rem;
}
.desclass div:nth-child(2) {
  width: 30%;
  text-align: center;
  font-size: 0.34rem;
  color: #f55814;
  font-weight: bold;
}
.divone,
.divtwo {
  height: 1px;
  background-color: #ff894d;
  width: 30%;
}
.pcone {
  text-align: center;
  font-size: 0.28rem;
  color: #222222;
  margin-bottom: 0.12rem;
  font-weight: 500;
}
.pctwo {
  text-align: center;
  font-size: 0.24rem;
  color: #787878;
  font-weight: 500;
}
.process {
  margin-top: 2.04rem;
  width: 68%;
  margin-left: 16%;
}
.process > p {
  color: #222222;
  font-weight: 500;
  font-size: 0.34rem;
}
.proliu {
  display: flex;
  align-items: center;
  border-left-color: #f55814;
  border-left-style: solid;
  border-left-width: 0.1rem;
  margin-top: 0.69rem;
  margin-left: 0.2rem;
}
.liuclass div {
  margin-bottom: 0.79rem;
  display: flex;
  margin-left: -0.15rem;
}
.liuclass div div:first-child {
  background-color: #f55814;
  width: 0.5rem;
  height: 0.5rem;
  font-size: 0.36rem;
  display: inline-block;
  text-align: center;
  line-height: 0.5rem;
  font-weight: 500;
  color: #ffffff;
  margin-right: 0.34rem;
  border-radius: 50%;
  margin-bottom: 0;
}
.liuclass div p {
  font-size: 0.36rem;
  color: #222222;
  font-weight: 500;
}
.liuclass div:last-child {
  margin-bottom: 0;
}
.centerclass {
  background-color: #ffffff;
  padding-bottom: 3rem;
}
.bbnn {
  margin-top: 2.3rem;
}
/* .xieyi {
  padding: 0 0.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.3rem;
}
.xieyi div label {
  font-size: 0.26rem;
  color: #000000;
}
.xieyi div input:checked {
  background-color: #f55613;
  color: #f55613;
}
.xieyi > p {
  color: #f55613;
  font-weight: 400;
} */
.box {
  display: flex;
  margin-left: 0.34rem;
  margin-top: 0.3rem;
  line-height: 0.52rem;
}
.box .box1 {
  margin-left: 0.15rem;
  width: 4.42rem;
  height: 0.52rem;
  font-size: 0.26rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 0.52rem;
}
.box p {
  margin-left: 0.7rem;
  width: 1.04rem;
  height: 0.52rem;
  font-size: 0.26rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #f55613;
  line-height: 0.52rem;
}
.popups {
  z-index: 1100;
  position: fixed;
  background-color: #ffffff;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  overflow: hidden;
}
.popups p {
  margin: 0 0.69rem;
  min-width: 3.05rem;
  margin-bottom: 0.64rem;
  text-align: center;
  font-size: 0.3rem;
  color: #000000;
}
.popups > div:first-child {
  display: flex;
  justify-content: space-between;
  margin: 0.34rem 0.33rem 0.22rem 0;
}
.popups > div:first-child img {
  width: 0.34rem;
}
#btqone,
#btqtwo {
  width: 50%;
  margin: 0;
  border: none;
  padding: 0.19rem 0;
}
#btqone {
  background-color: #f65a16;
  color: #ffffff;
}
#btqtwo {
  background-color: #dcdcdc;
}
</style>
